var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    [
      _c("tev-navbar", { attrs: { title: "Goodbye" } }),
      _c(
        "v-content",
        { staticClass: "logout-wrap" },
        [
          _c(
            "v-layout",
            { attrs: { row: "", wrap: "", "pt-4": "" } },
            [
              _c(
                "v-flex",
                { staticClass: "mb-5", attrs: { xs12: "", sm12: "" } },
                [
                  _c(
                    "v-container",
                    [
                      _c(
                        "v-layout",
                        { attrs: { row: "", wrap: "" } },
                        [
                          _c(
                            "v-flex",
                            {
                              staticClass: "mb-5 text-xs-center",
                              attrs: { xs12: "", sm12: "" },
                            },
                            [
                              _c("tev-pagetitle", {
                                attrs: {
                                  title: "Logout successful",
                                  subtitle: "You have successfully logged out.",
                                },
                              }),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "mb-5",
                                  attrs: {
                                    color: "primary",
                                    large: "",
                                    depressed: "",
                                    href: _vm.loginUrl,
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                Login\n              "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }