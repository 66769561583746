<template>
  <v-app>
    <tev-navbar title="Goodbye" />
    <v-content class="logout-wrap">
      <v-layout row wrap pt-4>
        <v-flex xs12 sm12 class="mb-5">
          <v-container>
            <v-layout row wrap>
              <v-flex xs12 sm12 class="mb-5 text-xs-center">
                <tev-pagetitle title="Logout successful" subtitle="You have successfully logged out." />
                <v-btn class="mb-5" color="primary" large depressed :href="loginUrl">
                  Login
                </v-btn>
              </v-flex>
            </v-layout>
          </v-container>
        </v-flex>
      </v-layout>
    </v-content>
  </v-app>
</template>
<script>
import config from '@/config'

export default {
  name: 'Logout',
  data() {
    return {
      loginUrl: config.loginUrl,
    }
  },
  created() {
    this.$store.dispatch('logout')
  },
  updated() {
    this.$store.dispatch('logout')
  },
}
</script>

<style>
.logout-wrap .v-content__wrap {
    max-width: 100%;
    margin: 0 auto;
}
</style>